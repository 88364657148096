<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Asignaciones Opex Capex</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Hidrocarburos</li>
                  <li class="breadcrumb-item active">Administrativo</li>
                  <li class="breadcrumb-item active">Básico</li>
                  <li class="breadcrumb-item active">
                    Asignaciones Opex Capex
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-md-12">
                    <div class="btn-group float-right">
                      <button
                        type="button"
                        class="btn bg-navy"
                        @click="getIndex()"
                      >
                        <i class="fas fa-sync-alt fa-spin"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-primary"
                        data-toggle="modal"
                        data-target="#modal-asignaciones"
                        @click="abrirModal('Crear', null)"
                        v-if="
                          $store.getters.can(
                            'hidrocarburos.asignacionOpexCapex.create'
                          )
                        "
                      >
                        <i class="fas fa-plus"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body p-0">
                <table
                  id="users"
                  class="
                    table table-bordered table-striped table-hover table-sm
                  "
                >
                  <thead>
                    <tr>
                      <th class="text-center p-3">
                        Nombre
                      </th>
                      <th>
                        Producto Líquido
                        <v-select
                          :class="[
                            $store.getters.getDarkMode ? 'dark-vselect' : '',
                            'form-control form-control-sm p-0',
                          ]"
                          v-model="producto_liquido_filter"
                          placeholder="Producto Liquido"
                          label="nombre"
                          :options="listasForms.productos_liquidos"
                          @input="[selectFilterProductoLiquido(), getIndex()]"
                        ></v-select>
                      </th>
                      <th>
                        Tipo Vehículo
                        <v-select
                          :class="[
                            $store.getters.getDarkMode ? 'dark-vselect' : '',
                            'form-control form-control-sm p-0',
                          ]"
                          v-model="tipo_vehiculo_filter"
                          placeholder="Tipo Vehículo"
                          label="nombre"
                          :options="listasForms.tipos_vehiculos"
                          @input="[selectFilterTipoVehiculo(), getIndex()]"
                        ></v-select>
                      </th>
                      <th>
                        Ruta
                        <v-select
                          :class="[
                            $store.getters.getDarkMode ? 'dark-vselect' : '',
                            'form-control form-control-sm p-0',
                          ]"
                          v-model="ruta_filter"
                          placeholder="Ruta"
                          label="nombre"
                          :options="listasForms.rutas"
                          @input="[selectFilterRuta(), getIndex()]"
                        ></v-select>
                      </th>
                      <th>
                        Categoria
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.categoria"
                          @change="getIndex()"
                        >
                          <option value="">Todos...</option>
                          <option
                            v-for="categoria in listasForms.categorias"
                            :key="categoria.numeracion"
                            :value="categoria.numeracion"
                          >
                            {{ categoria.descripcion }}
                          </option>
                        </select>
                      </th>
                      <th>
                        Estado
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.estado"
                          @change="getIndex()"
                        >
                          <option value="">Todos...</option>
                          <option
                            v-for="estado in listasForms.estados"
                            :key="estado.numeracion"
                            :value="estado.numeracion"
                          >
                            {{ estado.descripcion }}
                          </option>
                        </select>
                      </th>
                      <th class="text-center p-3">Acción</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="asignacion in asignacionesOpexCapex.data"
                      :key="asignacion.id"
                    >
                      <td class="text-center">{{ asignacion.nombre }}</td>
                      <td class="text-center">
                        {{ asignacion.producto_liquido.nombre }}
                      </td>
                      <td class="text-center">
                        {{ asignacion.tipo_vehiculo.nombre }}
                      </td>
                      <td class="text-center">{{ asignacion.ruta.nombre }}</td>
                      <td class="text-center">{{ asignacion.nCategoria }}</td>
                      <td class="text-center">
                        <span
                          class="badge"
                          :class="[
                            asignacion.estado == 1
                              ? 'badge-success'
                              : 'badge-danger',
                          ]"
                        >
                          {{ asignacion.estado == 1 ? "ACTIVO" : "INACTIVO" }}
                        </span>
                      </td>
                      <td style="width: 50px">
                        <div class="btn-group float-right">
                          <button
                            type="button"
                            class="btn btn-sm bg-navy"
                            data-toggle="modal"
                            data-target="#modal-asignaciones"
                            @click="abrirModal('Editar', asignacion)"
                            v-if="
                              $store.getters.can(
                                'hidrocarburos.asignacionOpexCapex.edit'
                              )
                            "
                          >
                            <i class="fas fa-edit"></i>
                          </button>
                          <button
                            type="button"
                            class="btn btn-sm btn-danger"
                            @click="destroy(asignacion.id)"
                            v-if="
                              $store.getters.can(
                                'hidrocarburos.asignacionOpexCapex.delete'
                              )
                            "
                          >
                            <i class="fas fa-trash-alt"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="asignacionesOpexCapex.total">
                  <p>
                    Mostrando del <b>{{ asignacionesOpexCapex.from }}</b> al
                    <b>{{ asignacionesOpexCapex.to }}</b> de un total:
                    <b>{{ asignacionesOpexCapex.total }}</b> Registros
                  </p>
                </div>
                <div class="float-left" v-else>
                  <p>
                    <span class="badge badge-danger">
                      No hay registros para mostrar
                    </span>
                  </p>
                </div>
                <pagination
                  :data="asignacionesOpexCapex"
                  @pagination-change-page="getIndex"
                  :limit="5"
                  class="float-right"
                ></pagination>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <!-- Modal -->
    <div
      class="modal fade"
      id="modal-asignaciones"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">{{ modal.title }}</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal"
              @click="limpiarModal()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <!-- Nombre -->
            <div class="form-group">
              <label for="nombre">Nombre</label>
              <input
                type="text"
                class="form-control form-control-sm"
                id="nombre"
                placeholder="Nombre"
                v-model="form.nombre"
                :class="$v.form.nombre.$invalid ? 'is-invalid' : 'is-valid'"
              />
            </div>
            <!-- Producto Liquido -->
            <div class="form-group">
              <label for="producto_liquido">Producto Líquido</label>
              <v-select
                :class="[
                  $store.getters.getDarkMode ? 'dark-vselect' : '',
                  'form-control form-control-sm p-0',
                  $v.form.producto_liquido_id.$invalid
                    ? 'is-invalid'
                    : 'is-valid',
                ]"
                v-model="producto_liquido"
                placeholder="Producto Liquido"
                label="nombre"
                :options="listasForms.productos_liquidos"
                @input="selectProductoLiquido()"
              ></v-select>
            </div>
            <!-- Tipo Vehículo -->
            <div class="form-group">
              <label for="tipo_vehiculo">Tipo Vehículo</label>
              <v-select
                :class="[
                  $store.getters.getDarkMode ? 'dark-vselect' : '',
                  'form-control form-control-sm p-0',
                  $v.form.tipo_vehiculo_id.$invalid ? 'is-invalid' : 'is-valid',
                ]"
                v-model="tipo_vehiculo"
                placeholder="Tipo Vehiculo"
                label="nombre"
                :options="listasForms.tipos_vehiculos"
                @input="selectTipoVehiculo()"
              ></v-select>
            </div>
            <!-- Ruta -->
            <div class="form-group">
              <label for="ruta">Ruta</label>
              <v-select
                :class="[
                  $store.getters.getDarkMode ? 'dark-vselect' : '',
                  'form-control form-control-sm p-0',
                  $v.form.ruta_id.$invalid ? 'is-invalid' : 'is-valid',
                ]"
                v-model="ruta"
                placeholder="Ruta"
                label="nombre"
                :options="listasForms.rutas"
                @input="selectRuta()"
              ></v-select>
            </div>
            <!-- Categoria -->
            <div class="form-group">
              <label for="categoria">Categoria</label>
              <select
                v-model="form.categoria"
                :class="[
                  $v.form.categoria.$invalid ? 'is-invalid' : 'is-valid',
                  'form-control form-control-sm',
                ]"
              >
                <option value="">Todos...</option>
                <option
                  v-for="categoria in listasForms.categorias"
                  :key="categoria.numeracion"
                  :value="categoria.numeracion"
                >
                  {{ categoria.descripcion }}
                </option>
              </select>
            </div>
            <!-- Estado -->
            <div class="form-group">
              <label for="estado">Estado</label>
              <select
                :class="[
                  $v.form.estado.$invalid ? 'is-invalid' : 'is-valid',
                  'form-control form-control-sm',
                ]"
                v-model="form.estado"
              >
                <option value="">Todos...</option>
                <option
                  v-for="estado in listasForms.estados"
                  :key="estado.numeracion"
                  :value="estado.numeracion"
                >
                  {{ estado.descripcion }}
                </option>
              </select>
            </div>
          </div>
          <!-- Guardar -->
          <div
            class="modal-footer justify-content-between"
            v-show="
              !$v.form.$invalid &&
                $store.getters.can('hidrocarburos.asignacionOpexCapex.save')
            "
          >
            <button type="button" class="btn btn-primary" @click="save()">
              Guardar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";
import pagination from "laravel-vue-pagination";
import Loading from "../../../../components/Loading";
import vSelect from "vue-select";

export default {
  name: "AsignacionOpexCapexIndex",
  components: {
    pagination,
    Loading,
    vSelect,
  },
  data() {
    return {
      filtros: {
        tipo_vehiculo_id: null,
        ruta_id: null,
        producto_liquido_id: null,
        estado: null,
      },
      producto_liquido_filter: {},
      ruta_filter: {},
      tipo_vehiculo_filter: {},
      producto_liquido: {},
      tipo_vehiculo: {},
      ruta: {},
      asignacionesOpexCapex: {},
      metodo: null,
      listasForms: {
        tipos_vehiculos: [],
        productos_liquidos: [],
        rutas: [],
        categorias: [],
        estados: [],
      },
      modal: {
        title: "",
        accion: "",
      },
      form: {
        id: null,
        nombre: null,
        tipo_vehiculo_id: null,
        producto_liquido_id: null,
        ruta_id: null,
        categoria: null,
        estado: null,
      },
      cargando: false,
    };
  },
  validations: {
    form: {
      nombre: {
        required,
      },
      tipo_vehiculo_id: {
        required,
      },
      producto_liquido_id: {
        required,
      },
      ruta_id: {
        required,
      },
      categoria: {
        required,
      },
      estado: {
        required,
      },
    },
  },
  methods: {
    async getIndex(page = 1) {
      this.cargando = true;
      await axios
        .get("/api/hidrocarburos/asignacionesOpexCapex?page=" + page, {
          params: this.filtros,
        })
        .then((response) => {
          this.asignacionesOpexCapex = response.data;
          this.cargando = false;
        });
      this.cargando = false;
    },

    selectFilterProductoLiquido() {
      this.filtros.producto_liquido_id = null;
      if (this.producto_liquido_filter) {
        this.filtros.producto_liquido_id = this.producto_liquido_filter.id;
      }
    },

    selectFilterTipoVehiculo() {
      this.filtros.tipo_vehiculo_id = null;
      if (this.tipo_vehiculo_filter) {
        this.filtros.tipo_vehiculo_id = this.tipo_vehiculo_filter.id;
      }
    },

    selectFilterRuta() {
      this.filtros.ruta_id = null;
      if (this.ruta_filter) {
        this.filtros.ruta_id = this.ruta_filter.id;
      }
    },

    async getProductosLiquidos() {
      let me = this;
      await axios
        .get("api/hidrocarburos/Productos/lista")
        .then(function(response) {
          me.listasForms.productos_liquidos = response.data;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    async getTiposVehiculos() {
      await axios
        .get("/api/admin/tiposVehiculos/lista", {
          params: {
            linea_negocio_id: 2,
          },
        })
        .then((response) => {
          this.listasForms.tipos_vehiculos = response.data;
        });
    },

    async getRutas() {
      await axios.get("/api/admin/rutas/lista").then((response) => {
        this.listasForms.rutas = response.data;
      });
    },

    async getCategorias() {
      await axios.get("/api/lista/72").then((response) => {
        this.listasForms.categorias = response.data;
      });
    },

    async getEstados() {
      await axios.get("/api/lista/1").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    abrirModal(accion, asignacion) {
      this.modal.accion = accion;
      this.modal.title = accion + " Asignación";
      if (accion == "Editar") {
        this.metodo = "PUT";
        this.form = {
          id: asignacion.id,
          nombre: asignacion.nombre,
          tipo_vehiculo_id: asignacion.tipo_vehiculo_id,
          producto_liquido_id: asignacion.producto_liquido_id,
          ruta_id: asignacion.ruta_id,
          categoria: asignacion.categoria,
          estado: asignacion.estado,
        };

        this.producto_liquido = asignacion.producto_liquido;
        this.tipo_vehiculo = asignacion.tipo_vehiculo;
        this.ruta = asignacion.ruta;
      } else {
        this.metodo = "POST";
      }
    },

    selectProductoLiquido() {
      this.form.producto_liquido_id = null;
      if (this.producto_liquido) {
        this.form.producto_liquido_id = this.producto_liquido.id;
      }
    },

    selectTipoVehiculo() {
      this.form.tipo_vehiculo_id = null;
      if (this.tipo_vehiculo) {
        this.form.tipo_vehiculo_id = this.tipo_vehiculo.id;
      }
    },

    selectRuta() {
      this.form.ruta_id = null;
      if (this.ruta) {
        this.form.ruta_id = this.ruta.id;
      }
    },

    limpiarModal() {
      this.form = {
        id: null,
        nombre: null,
        tipo_vehiculo_id: null,
        producto_liquido_id: null,
        ruta_id: null,
        categoria: null,
        estado: null,
      };
      this.producto_liquido = {};
      this.tipo_vehiculo = {};
      this.ruta = {};
    },

    async save() {
      if (!this.$v.form.$invalid) {
        this.cargando = true;
        await axios({
          method: this.metodo,
          url: "/api/hidrocarburos/asignacionesOpexCapex",
          data: this.form,
        })
          .then(async (response) => {
            this.$refs.closeModal.click();
            this.form = {};
            await this.getIndex();
            await this.$swal({
              icon: "success",
              title: "Se guardo exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.cargando = false;
          })
          .catch((e) => {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error, vualeva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.cargando = false;
          });
      }
    },

    destroy(id) {
      this.$swal({
        title: "Esta seguro de eliminar esta Asignación?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        if (result.value) {
          axios
            .delete("/api/hidrocarburos/asignacionesOpexCapex/" + id)
            .then((response) => {
              this.getIndex();
              this.$swal({
                icon: "success",
                title: "Se elimino exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            });
        }
      });
    },
  },
  async mounted() {
    await this.getIndex();
    await this.getEstados();
    await this.getProductosLiquidos();
    await this.getTiposVehiculos();
    await this.getRutas();
    await this.getCategorias();
  },
};
</script>
